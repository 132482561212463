import axios from "axios";

export const requests = (endpointType?: "BACKEND" | "SIMULATOR") => {
  const type = endpointType ?? "BACKEND";
  const requestObj = axios.create({
    baseURL:
      type === "SIMULATOR"
        ? process.env.REACT_APP_SIMULATOR_ENDPOINT
        : process.env.REACT_APP_BACKEND_ENDPOINT,
    timeout: 600000,
    headers: {
      Authorization: "null",
      "Content-Type": "application/json",
      accept: "application/json",
    },
  });

  requestObj.interceptors.response.use((response: any) => {
    return response;
  }, error);

  return requestObj;
};

async function error(error: any) {
  if (typeof error.response === "undefined") {
    // window.location.href = '/error404/';
    return await Promise.reject(error);
  }

  if (
    error.response.data.code === "token_not_valid" &&
    error.response.status === 401 &&
    error.response.statusText === "Unauthorized"
  ) {
  }

  // specific error handling done elsewhere
  return await Promise.reject(error);
}
