import * as React from "react";

import { Divider, Grid, IconButton, Paper, Typography } from "@mui/material";
import { TEXTS } from "../../../../../common/texts";
import ClearIcon from "@mui/icons-material/Clear";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { StyledButton } from "../style";
import DriverChart from "./DriverChart";
import { Driver } from "../../../../../common/requests/types";
import {
  DriverRangeType,
  useSimulatorStore,
} from "../../../../../store/simulator/simulator";

interface DriverCardProps {
  driver: Driver;
  onChange: (driverId: string, value: DriverRangeType) => void;
  onClose: () => void;
}

export default function DriverCard(props: DriverCardProps) {
  const statStates: string[] = [
    "very low",
    "low",
    "medium",
    "high",
    "very high",
  ];
  const [status, setStatus] = React.useState<number>(2);
  const [boundValue, setBoundValue] = React.useState<number>();
  const [selectedDrivers, setSelectedDrivers] = useSimulatorStore((state) => [
    state.selectedDrivers,
    state.setSelectedDrivers,
  ]);

  function getFormatState() {
    if (status === 2) return "follow the expected trajectory";
    if (status < 2)
      return `be ${boundValue?.toFixed(2)} times lower than expected`;
    else return `be ${boundValue?.toFixed(2)} times higher than expected`;
  }

  return (
    <Paper style={{ borderRadius: 8, width: "100%", height: 300 }}>
      <div style={{ padding: 10, paddingLeft: 30 }}>
        <Grid container>
          <Grid item xs={10} display="flex" alignItems="center">
            <Typography variant="subtitle2" noWrap>
              {props.driver.name}
            </Typography>
          </Grid>
          <Grid item xs={2} display="flex" justifyContent="flex-end">
            <IconButton
              disableRipple
              onClick={props.onClose}
              style={{ padding: 5 }}
            >
              <ClearIcon />
            </IconButton>
          </Grid>
          <Typography variant="body3">{TEXTS.DRIVER_CARD_PROMPT}</Typography>
        </Grid>
        <Grid container>
          <Grid item xs={7}>
            <div
              style={{
                height: "160px",
                width: "100%",
                padding: 5,
                paddingBottom: 0,
              }}
            >
              <DriverChart
                driverId={props.driver.driver_id}
                status={statStates[status] as DriverRangeType}
                setBoundValue={setBoundValue}
              />
            </div>
          </Grid>
          <Grid
            item
            xs={5}
            display="flex"
            flexDirection="column"
            gap={3}
            justifyContent="center"
            alignItems="center"
          >
            <StyledButton
              startIcon={<ArrowUpwardIcon />}
              variant="outlined"
              sx={{ width: "130px" }}
              disabled={status === statStates.length - 1}
              onClick={() =>
                setStatus((prevStatus) => {
                  const updatedState = prevStatus + 1;
                  setSelectedDrivers({
                    ...selectedDrivers,
                    [props.driver.driver_id]: statStates[
                      updatedState
                    ] as DriverRangeType,
                  });
                  return updatedState;
                })
              }
            >
              <Typography variant="body2">More</Typography>{" "}
            </StyledButton>
            <StyledButton
              startIcon={<ArrowDownwardIcon />}
              variant="outlined"
              sx={{ width: "130px" }}
              disabled={status === 0}
              onClick={() => {
                setStatus((prevStatus) => {
                  const updatedState = prevStatus - 1;
                  setSelectedDrivers({
                    ...selectedDrivers,
                    [props.driver.driver_id]: statStates[
                      updatedState
                    ] as DriverRangeType,
                  });
                  return updatedState;
                });
              }}
            >
              <Typography variant="body2">Less</Typography>{" "}
            </StyledButton>
          </Grid>
        </Grid>
      </div>
      <Divider />
      <Grid
        container
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        style={{ padding: 15, maxHeight: 20 }}
      >
        <Typography variant="body3">
          You predict that {props.driver.name} will {getFormatState()} in 2030.
        </Typography>
      </Grid>
    </Paper>
  );
}
