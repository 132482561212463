import { ENDPOINTS } from "../../../../common/endpoints";
import { requests } from "../../../../common/requests";
import {
  DriverRelationship,
  DriverTimeSeries,
  Drivers,
  Proxies,
} from "../../../../common/requests/types";
import {
  DriverRangeType,
  SelectedDrivers,
} from "../../../../store/simulator/simulator";

export interface RunSimulateProps {
  num_iters: number;
  driver_relationships: DriverRelationship[];
  proxies: Proxies;
  drivers: Drivers;
  selected_drivers: SelectedDrivers;
  driver_timeseries: DriverTimeSeries[];
}

export interface ChartDataProps {
  drivers: Drivers;
  selected_drivers: SelectedDrivers;
}

export const runSimulation = (props: RunSimulateProps) => {
  return requests("SIMULATOR").post(ENDPOINTS.SIMULATE_ENDPOINT, props);
};

export interface ChartData {
  1: number;
  2: number;
  3: number;
  4: number;
  driver_id: string;
  output_id: string;
  label: DriverRangeType;
}

export const getChartData = (props: ChartDataProps) => {
  return requests("SIMULATOR").post<ChartData[]>(
    ENDPOINTS.CHART_DATA_ENDPOINT,
    props
  );
};
