import * as React from "react";
import * as S from "./style";
import { Typography } from "@mui/material";
import { ColorsEnum } from "../../common/theme";

interface ProgressBarProps {
  progressPct: number;
  benchmarkPct?: number;
  showWithin?: boolean;
}

const ProgressBar = (props: ProgressBarProps) => {
  return (
    <div style={{ marginTop: props.showWithin ? 0 : -30 }}>
      {props.showWithin ? null : (
        <div
          id="likelihood"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            paddingBottom: 5,
          }}
        >
          <Typography variant="body3" align="right">
            <b>{props.progressPct.toFixed(0)}%</b> Likelihood
          </Typography>
        </div>
      )}
      <S.ProgressBarWrapper>
        {props.benchmarkPct && props.benchmarkPct < props.progressPct ? (
          <React.Fragment>
            <S.BenchmarkFill sx={{ width: `${props.benchmarkPct}%` }} />
            <div
              style={{
                width: `${props.progressPct - props.benchmarkPct}%`,
                backgroundColor: ColorsEnum.amethyst,
                borderLeft: `1px solid ${ColorsEnum.black}`,
              }}
            />
          </React.Fragment>
        ) : props.benchmarkPct && props.benchmarkPct >= props.progressPct ? (
          <React.Fragment>
            <S.BenchmarkFill sx={{ width: `${props.progressPct}%` }} />
            <div
              style={{
                width: `${props.benchmarkPct - props.progressPct}%`,
                backgroundColor: ColorsEnum.primary,
                borderLeft: `1px solid ${ColorsEnum.black}`,
              }}
            />
          </React.Fragment>
        ) : null}
        {!props.benchmarkPct ? (
          <S.DefaultFill sx={{ width: `${props.progressPct}%` }} />
        ) : null}
        <S.RemainderFill
          sx={{ display: "flex", width: `${100 - props.progressPct}%` }}
        >
          <div
            style={{
              width: "50%",
              justifyContent: "flex-end",
              display: "flex",
              paddingLeft: 0,
            }}
          >
            {props.benchmarkPct ? (
              <Typography
                variant="body3"
                align="left"
                fontWeight="bold"
                sx={{
                  color:
                    props.progressPct > props.benchmarkPct
                      ? ColorsEnum.amethyst
                      : ColorsEnum.primary,
                }}
              >
                {" "}
                {props.progressPct > props.benchmarkPct ? "+" : "-"}
                {Math.abs(props.progressPct - props.benchmarkPct).toFixed(
                  1
                )}%{" "}
              </Typography>
            ) : (
              <></>
            )}
          </div>
          {props.showWithin ? (
            <div
              id="likelihood"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                paddingBottom: 0,
              }}
            >
              <Typography variant="body3" align="right">
                <b>{props.progressPct.toFixed(0)}%</b> Likelihood
              </Typography>
            </div>
          ) : null}
        </S.RemainderFill>
      </S.ProgressBarWrapper>
    </div>
  );
};

export default ProgressBar;
