import * as React from "react";
import * as S from "./style";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { ROUTES } from "../../../common/routes";
import { UserCredential } from "firebase/auth";

import { useNavigate } from "react-router-dom";
import {
  Alert,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import { AuthContext } from "../../../providers/AuthProvider";
import GoogleButton from "./GoogleButton";
import { ColorsEnum } from "../../../common/theme";
import { ContainerWrapper } from "../../../components/Wrappers/ContainerWrapper";

import SynthesisLogo from "../../../assets/logo-full.png";
import MicrosoftButton from "./MicrosoftButton";
import {
  DomainNotWhitelistedError,
  checkWhitelisted,
} from "../../../common/firebase/auth";

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href={"https://www.synthesis.partners/"}>
        Synthesis Partners
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function SignIn() {
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string | null>(null);
  const { user, emailLogin, googleLogin, microsoftLogin } =
    React.useContext(AuthContext)!;
  const navigate = useNavigate();

  if (user && checkWhitelisted(user.providerData[0].email)) {
    navigate(ROUTES.LANDING);
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    emailLogin(data.get("email") as string, data.get("password") as string)
      .then((userCredential) => {
        userCredential.user.getIdToken().then((s: any) => {
          setError(null);
          navigate(ROUTES.LANDING);
        });
      })
      .catch((error) => {
        setError("Wrong username or password.");
      });
  };

  const handleSSO = async (type: "google" | "microsoft") => {
    try {
      const response: UserCredential =
        type === "google" ? await googleLogin() : await microsoftLogin();
      const domainAllowed = checkWhitelisted(
        response.user.providerData[0].email
      );
      if (domainAllowed) {
        response.user
          .getIdToken()
          .then((s: any) => {
            setError(null);
            navigate(ROUTES.LANDING);
          })
          .catch((error) => {
            setError("You have not yet been granted access to the simulator.");
          });
      } else {
        throw new DomainNotWhitelistedError();
      }
    } catch (err: any) {
      if (err.code === "auth/admin-restricted-operation") {
        setError("You have not yet been granted access to the simulator.");
      } else if (err instanceof DomainNotWhitelistedError) {
        setError(err.message);
      } else {
        console.log("User Closed Login Popup.");
      }
    }
  };

  return (
    <ContainerWrapper>
      <Box
        sx={{
          marginTop: 8,
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          overflow: "hidden",
        }}
      >
        <S.SignInHeaders id="sign-in-headers">
          <img src={SynthesisLogo} width="250px" />
          <Typography component="h1" variant="subtitle1">
            Login to Scenario Simulator
          </Typography>
        </S.SignInHeaders>
        <Box>
          <Box component="form" onSubmit={handleSubmit} noValidate>
            {error ? (
              <Alert
                severity="error"
                variant="filled"
                sx={{
                  width: "100%",
                  color: ColorsEnum.white,
                  fontWeight: "bold",
                }}
              >
                {error}
              </Alert>
            ) : null}
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <FormControl fullWidth variant="standard">
              <InputLabel
                htmlFor="outlined-adornment-password"
                variant="outlined"
              >
                Password
              </InputLabel>
              <OutlinedInput
                required
                fullWidth
                name="password"
                label="Password"
                type={showPassword ? "text" : "password"}
                id="password"
                autoComplete="current-password"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword((show) => !show)}
                      onMouseDown={(
                        event: React.MouseEvent<HTMLButtonElement>
                      ) => event.preventDefault()}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <S.StyledButton
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, fontWeight: "bold" }}
            >
              Sign In
            </S.StyledButton>
          </Box>
          <Divider
            style={{ paddingTop: 20, paddingBottom: 20 }}
            sx={{ bgColor: ColorsEnum.warmgray2 }}
          >
            <Typography variant="subtitle2" color={ColorsEnum.warmgray2}>
              {" "}
              or{" "}
            </Typography>
          </Divider>
          <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
            <GoogleButton signIn handleLogin={() => handleSSO("google")} />
          </div>
          <Copyright sx={{ mt: 8, mb: 4 }} />
        </Box>
      </Box>
    </ContainerWrapper>
  );
}
