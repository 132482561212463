import * as React from "react";
import { Grid, Skeleton, Typography } from "@mui/material";
import useSimulatorStore from "../../../../../store/simulator";
import { Scenario } from "../../../../../common/requests/types";
import ProgressBar from "../../../../../components/ProgressBar";
import { ColorsEnum } from "../../../../../common/theme";
import ErrorOccurred from "../ErrorOccurred";

interface UpdatedScenariosProps {
  scenarios: Scenario[];
}

export default function UpdatedScenarios(props: UpdatedScenariosProps) {
  const simulatorResults = useSimulatorStore((state) => state.simulatorResults);

  const textItem = simulatorResults.scenarioLikelihoods ? (
    <Typography variant="body3">
      In this new simulation, the scenario with the highest probability is{" "}
      <b>
        {
          props.scenarios[
            Number(
              Object.keys(
                simulatorResults.scenarioLikelihoods.scenario_weightages
              )?.reduce((a: any, b: any) =>
                simulatorResults.scenarioLikelihoods.scenario_weightages[a] >
                simulatorResults.scenarioLikelihoods.scenario_weightages[b]
                  ? a
                  : b
              )
            ) - 1
          ].name
        }
      </b>
    </Typography>
  ) : (
    <Typography variant="body3">
      Run a simulation to find out the scenario with the highest probability!
    </Typography>
  );

  return (
    <Grid container display="flex" flexDirection="column">
      <Typography variant="subtitle1">
        Updated Scenario Probabilities
      </Typography>
      {simulatorResults.loading ? null : textItem}
      {simulatorResults.error ? (
        <ErrorOccurred height={250} />
      ) : simulatorResults.loading ? (
        <div style={{ paddingTop: 20 }}>
          <Skeleton variant="rectangular" animation="wave" height={250} />
        </div>
      ) : (
        <Grid container sx={{ paddingTop: 3 }} spacing={2}>
          {Object.keys(simulatorResults).length !== 0
            ? props.scenarios.sort((a, b) => a.quadrant - b.quadrant).map(
                (
                  scenario,
                  index // Ensure only four items are processed
                ) => (
                  <Grid item xs={6} key={index}>
                    <div
                      style={{
                        minHeight: "100px",
                        padding: "15px 20px",
                        width: "100%",
                        border: `1px solid ${ColorsEnum.coolgray6}`,
                        borderRadius: "8px",
                      }}
                    >
                      <Typography variant="subtitle2">
                        {scenario.name}
                      </Typography>
                      <Typography variant="body3">
                        Scenario {scenario.quadrant}
                      </Typography>
                      <div style={{ paddingTop: 10 }}>
                        <ProgressBar
                          progressPct={
                            simulatorResults.scenarioLikelihoods
                              .scenario_weightages[scenario.quadrant] * 100
                          }
                          benchmarkPct={scenario.pct_likelihood * 100}
                        />
                      </div>
                    </div>
                  </Grid>
                )
              )
            : null}
        </Grid>
      )}
    </Grid>
  );
}
