import * as React from "react";
import Box from "@mui/material/Box";
import { Grid, Skeleton, Typography } from "@mui/material";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { Drivers, Proxies } from "../../../../common/requests/types";
import columnDefinitions from "./specification";
import { TEXTS } from "../../../../common/texts";

interface DriverProxyPanelProps {
  proxies: Proxies;
  drivers: Drivers;
  loading: boolean;
}

interface DriverProxyData {
  id: string;
  proxyId: string;
  market: string;
  category: string;
  name: string;
  units: string;
  updateFrequency: string;
  dataSource: string;
  description?: string;
}

export default function DriverProxyPanel(props: DriverProxyPanelProps) {
  const [data, setData] = React.useState<DriverProxyData[]>([]);

  React.useEffect(() => {
    setData(
      Object.keys(props.drivers).map((driverId: string) => {
        const driver = props.drivers[driverId];
        const proxy = props.proxies[driver.proxy_id];
        return {
          id: driverId,
          proxyId: proxy.proxy_id,
          market: proxy.market,
          name: driver.name,
          category: driver.category,
          description: driver.description === "NaN" ? null : driver.description,
          units: proxy.units,
          updateFrequency: proxy.update_frequency,
          dataSource: proxy.data_source,
        } as DriverProxyData;
      })
    );
  }, [props.proxies]);

  return (
    <Grid container style={{ padding: "20px 5px" }}>
      <Grid container>
        <Typography variant="h6">Drivers</Typography>
      </Grid>
      <Grid container>
        <Typography variant="body3">{TEXTS.DRIVER_DESCRIPTION}</Typography>
      </Grid>
      <Box sx={{ height: "100%", width: "100%", paddingTop: 2 }}>
        {props.loading ? (
          <Skeleton variant="rectangular" width="100%" height="500px" />
        ) : (
          <DataGrid
            rows={data}
            columns={columnDefinitions}
            columnVisibilityModel={{
              id: false,
            }}
            disableColumnSelector
            getRowHeight={() => "auto"}
            sx={{
              maxWidth: 1000,
              "& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus": {
                outline: "none",
              },
              [`& .${gridClasses.cell}`]: {
                py: 1,
              },
            }}
            hideFooter
          />
        )}
      </Box>
    </Grid>
  );
}
