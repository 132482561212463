export function formatNumSuffixes(value: number): string {
  if (value >= 1000000) {
    return (value / 1000000).toFixed(1) + "M";
  } else if (value >= 1000) {
    return (value / 1000).toFixed(1) + "K";
  } else {
    return value.toFixed(0);
  }
}

export function formatNumCommas(value: number): string {
  if (!value) return "0";
  return value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function capitalise(s: string) {
  var splitStr = s.toLowerCase().split(" ");
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(" ");
}
export const TEXTS = {
  SIMULATION_LOADING:
    "Running the simulations, this might take a while based on the number of iterations chosen",
  DRIVER_CARD_PROMPT:
    "What do you think this will look like in 2030? Place your bets below.",
  EMPTY_DRIVER_PANEL_PROMPT:
    "Add one or more drivers from the left, and alter the forecasted value to see how they affect the scenario outcome.",
  SIMULATOR_DESCRIPTION:
    "Use the simulator to understand how the likelihood of each scenario can change.",
  DRIVER_DESCRIPTION: "These are the forces shaping the future looks like.",
  CATEGORY_DIMENSION_DESCRIPTION:
    "How are the different category dimensions impacted in each scenario? Click on each of the scenarios x categories to find out more.",
  DRIVER_NO_DATA_AVAILABLE: "No data available for driver",
  PAGE_404:
    "The requested URL was not found on our servers. The page has most likely been moved, deleted, or possibly never existed.",
  PAGE_404_SUB: "Thats all we (and Happy) know for now.",
  OFFLINE: "You are currently offline. Data might be delayed or not updated!",
};
