import * as React from "react";
import * as S from "./style";
import { Grid, Skeleton, Typography } from "@mui/material";
import { Scenario } from "../../../../common/requests/types";
import ProgressBar from "../../../../components/ProgressBar";

interface BaselineScenariosProps {
  loading: boolean;
  scenarios: Scenario[];
}

export default function BaselineScenarios(props: BaselineScenariosProps) {
  return (
    <Grid container gap={2}>
      <Typography variant="h6" style={{ paddingTop: 20 }}>
        Baseline Scenarios
      </Typography>
      {props.loading
        ? Array(4)
            .fill(0)
            .map((_, i) => {
              return (
                <Skeleton
                  key={`skeleton_${i}`}
                  variant="rectangular"
                  width="100%"
                  height="100px"
                />
              );
            })
        : props.scenarios.sort((a, b) => a.quadrant - b.quadrant).map((scenario) => {
            return (
              <S.StyledGrid container key={scenario.scenario_id}>
                <Grid item xs={4} rowSpacing={5}>
                  <Typography variant="subtitle2">{scenario.name}</Typography>
                  <Typography variant="body2">{`Scenario ${
                    scenario.quadrant
                  }`}</Typography>
                  <div style={{ paddingTop: 25 }}>
                    <ProgressBar
                      progressPct={scenario.pct_likelihood * 100}
                      showWithin
                    />
                  </div>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={7}>
                  <Typography variant="body3">
                    {scenario.description}
                  </Typography>
                </Grid>
              </S.StyledGrid>
            );
          })}
    </Grid>
  );
}
