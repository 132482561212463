import * as React from "react";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import InfoIcon from "@mui/icons-material/Info";
import CasinoIcon from "@mui/icons-material/Casino";

import axios from "axios";
import { ContainerWrapper } from "../../components/Wrappers/ContainerWrapper";
import {
  DriverRelationship,
  Drivers,
  Proxies,
  Scenario,
} from "../../common/requests/types";
import {
  getProxies,
  getDriverRelationships,
  getDrivers,
  getScenarios,
} from "../../common/requests/queries";
import SideDrawer from "../../components/SideDrawer";
import { BaselineScenarios, DriverProxyPanel } from "./Information";
import { SimulationPlayground, SimulatorResults } from "./ScenarioSimulator";
import useSimulatorStore from "../../store/simulator";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ height: "100%", overflowY: "auto" }}
      sx={{
        padding: "0px 20px",
        "&::-webkit-scrollbar": {
          display: "none",
        },
      }}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            p: 0,
            width: "100%",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          {children}
        </Box>
      )}
    </Box>
  );
}

export default function Landing() {
  const [selection, setSelection] = React.useState<number>(0);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [iters, setIters] = React.useState<number>(1000);

  const [drivers, setDrivers] = React.useState<Drivers>({} as Drivers);
  const [proxies, setProxies] = React.useState<Proxies>({} as Proxies);
  const [driverRelationships, setDriverRelationships] = React.useState<
    DriverRelationship[]
  >([]);
  const [scenarios, setScenarios] = React.useState<Scenario[]>([]);
  const [selectedCategory, setSelectedCategory] = React.useState<string>();
  const initialised = useSimulatorStore((state) => state.initialised);

  React.useEffect(() => {
    setLoading(true);
    axios
      .all([getDrivers(), getScenarios() as any])
      .then(
        axios.spread((responseDrivers: any, responseScenarios) => {
          const drivers: Drivers = responseDrivers.data;
          setDrivers(drivers);

          let driverIds: string[] = [];
          let proxyIds: string[] = [];

          for (const [key, value] of Object.entries(drivers)) {
            driverIds.push(key);
            proxyIds.push(value.proxy_id);
          }

          setScenarios(responseScenarios.data);
          return axios.all([
            getDriverRelationships(driverIds),
            getProxies(proxyIds) as any,
          ]);
        })
      )
      .then(
        axios.spread((response1, response2) => {
          setDriverRelationships(response1.data);
          setProxies(response2.data);
        })
      )
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <ContainerWrapper>
      <SideDrawer iters={iters} setIters={setIters} />
      <Box
        component="main"
        sx={{
          padding: "0 50px",
          width: "100%",
          height: "100%",
          paddingBottom: 10,
        }}
      >
        <Box sx={{ borderBottom: 1, borderColor: "divider", paddingTop: 1 }}>
          <Tabs
            value={selection}
            onChange={(_: React.SyntheticEvent, newValue: number) => {
              setSelection(newValue);
            }}
            sx={{
              padding: 0,
              ".MuiButtonBase-root": {
                minHeight: 0,
              },
            }}
          >
            <Tab
              disableRipple
              sx={{ textTransform: "none" }}
              icon={<InfoIcon fontSize="medium" sx={{ padding: 0 }} />}
              iconPosition="start"
              label="Information"
            />
            <Tab
              disableRipple
              sx={{ textTransform: "none" }}
              icon={<CasinoIcon fontSize="medium" sx={{ padding: 0 }} />}
              iconPosition="start"
              label="Scenario Simulator"
            />
          </Tabs>
        </Box>
        <CustomTabPanel value={selection} index={0}>
          <BaselineScenarios loading={loading} scenarios={scenarios} />
          <DriverProxyPanel
            proxies={proxies}
            loading={loading}
            drivers={drivers}
          />
        </CustomTabPanel>
        <CustomTabPanel value={selection} index={1}>
          <SimulationPlayground
            iters={iters}
            loading={loading}
            drivers={drivers}
            proxies={proxies}
            scenarios={scenarios}
            setSelectedCategory={setSelectedCategory}
            driverRelationships={driverRelationships}
          />
          {initialised ? (
            <SimulatorResults setSelectedCategory={setSelectedCategory}
            selectedCategory={selectedCategory}
            drivers={drivers} scenarios={scenarios} />
          ) : null}
        </CustomTabPanel>
      </Box>
    </ContainerWrapper>
  );
}
