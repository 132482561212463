import { ENDPOINTS } from "../endpoints";
import { requests } from "../requests";
import {
  DriverRelationship,
  Drivers,
  ProjectOverview,
  Proxies,
  Scenario,
  DriverTimeSeries,
  DriverValues,
} from "./types";

export const getProjectOverview = () => {
  return requests().get<ProjectOverview>(
    `${ENDPOINTS.BASE_QUERY_ENDPOINT}/${process.env.REACT_APP_PROJECT_ID}`
  );
};

export const getDrivers = () => {
  return requests().get<Drivers>(
    `${ENDPOINTS.DRIVERS_QUERY_ENDPOINT}/${process.env.REACT_APP_PROJECT_ID}`
  );
};

export const getScenarios = () => {
  return requests().get<Scenario[]>(
    `${ENDPOINTS.SCENARIO_QUERY_ENDPOINT}/${process.env.REACT_APP_PROJECT_ID}`
  );
};

export const getDriverRelationships = (driverIds: string[]) => {
  return requests().post<DriverRelationship[]>(
    ENDPOINTS.CASUALRELATIONSHIPS_QUERY_ENDPOINT,
    {
      driver_ids: driverIds,
    }
  );
};

export const getProxies = (proxyIds: string[]) => {
  return requests().post<Proxies>(ENDPOINTS.PROXIES_QUERY_ENDPOINT, {
    proxy_ids: proxyIds,
  });
};

export const getDriverTimeseries = (driverId: string) => {
  return requests().get<DriverTimeSeries[]>(
    `${ENDPOINTS.DRIVER_TIMESERIES_QUERY_ENDPOINT}/${driverId}`
  );
};

export const getAllDriverTimeSeries = (driverIds: string[]) => {
  return requests().post<DriverTimeSeries[]>(
    ENDPOINTS.DRIVER_TIMESERIES_QUERY_ENDPOINT,
    {
      driver_ids: driverIds,
    }
  );
};

export const getLatestDriverTimeseries = () => {
  return requests().get<DriverValues>(
    ENDPOINTS.LATEST_DRIVER_TIMESERIES_QUERY_ENDPOINT
  );
};
