import { Button, Grid } from "@mui/material";
import { styled } from "@mui/system";
import { ColorsEnum } from "../../../../common/theme";

const containerMinHeight = 400;

export const AllDriverWrapper = styled("div")({
  "&::-webkit-scrollbar": {
    display: "none",
  },
  width: "100%",
  overflowY: "auto",
  height: "100%",
});

export const DriverRowWrapper = styled(Grid)({
  borderBottom: `1px solid ${ColorsEnum.coolgray5}`,
  padding: "5px 15px",
});

export const StyledDriverContainer = styled(Grid)({
  borderRadius: "8px",
  backgroundColor: ColorsEnum.coolgray5,
  minHeight: containerMinHeight,
  padding: 20,
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
});

export const StyledDriverList = styled(Grid)({
  borderRadius: "8px",
  border: `1px solid ${ColorsEnum.coolgray5}`,
  height: 500,
  width: "100%",
  overflowY: "hidden",
});

export const EmptyTextbox = styled("div")({
  height: "100%",
  padding: 50,
  color: ColorsEnum.warmgray1,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  minHeight: containerMinHeight,
});

export const StyledButton = styled(Button)({
  borderRadius: 8,
  color: ColorsEnum.black,
  borderColor: ColorsEnum.coolgray4,
  "&:hover": {
    borderColor: ColorsEnum.coolgray4,
  },
  padding: "2px 8px",
});
