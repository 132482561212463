import * as React from "react";
import * as S from "../style";
import { Grid, IconButton, Typography } from "@mui/material";
import { ColorsEnum } from "../../../../../common/theme";
import AddIcon from "@mui/icons-material/Add";
import { Driver } from "../../../../../common/requests/types";
import { capitalise } from "../../../../../common/texts";
import useSimulatorStore from "../../../../../store/simulator";

interface DriverPanelProps {
  drivers: Driver[];
}

export default function DriverPanel(props: DriverPanelProps) {
  const [selectedDrivers, setSelectedDrivers] = useSimulatorStore((state) => [
    state.selectedDrivers,
    state.setSelectedDrivers,
  ]);
  const selectedDriverId = Object.keys(selectedDrivers);
  const groupedDrivers = props.drivers.reduce((acc: any, driver: Driver) => {
    if (!acc[driver.category]) {
      acc[driver.category] = [];
    }
    acc[driver.category].push(driver);
    return acc;
  }, {});

  return (
    <S.AllDriverWrapper>
      {Object.keys(groupedDrivers).map((category: string) => {
        if (category.toLowerCase() === "output")
          return <React.Fragment key={`${category}-fragment`}></React.Fragment>;
        return (
          <React.Fragment key={`${category}-fragment`}>
            <S.DriverRowWrapper
              container
              key={category}
              sx={{ backgroundColor: ColorsEnum.coolgray5 }}
            >
              <Typography variant="subtitle2">
                {" "}
                {capitalise(category)}
              </Typography>
            </S.DriverRowWrapper>
            {groupedDrivers[category].map((driver: Driver) => (
              <S.DriverRowWrapper
                key={driver.driver_id}
                container
                sx={{
                  "&:hover": {
                    backgroundColor: ColorsEnum.coolgray7,
                    cursor: "pointer",
                  },
                  display: selectedDriverId.includes(driver.driver_id)
                    ? "none"
                    : "flex",
                }}
                onClick={() =>
                  setSelectedDrivers({
                    ...selectedDrivers,
                    [driver.driver_id]: "medium",
                  })
                }
              >
                <Grid item xs={11} display="flex" alignItems="center">
                  <Typography variant="body3">{driver.name}</Typography>
                </Grid>
                <Grid item xs={1}>
                  <IconButton style={{ padding: 0 }} disableRipple>
                    <AddIcon fontSize="inherit" />
                  </IconButton>
                </Grid>
              </S.DriverRowWrapper>
            ))}
          </React.Fragment>
        );
      })}
    </S.AllDriverWrapper>
  );
}
