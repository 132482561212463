import * as React from "react";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import { Typography } from "@mui/material";
import { ColorsEnum } from "../../../../../common/theme";
interface ErrorOccurredProps {
  height: number;
}
export default function ErrorOccurred(props: ErrorOccurredProps) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        gap: 10,
        color: ColorsEnum.warmgray1,
        justifyContent: "center",
        width: "100%",
        height: props.height,
        backgroundColor: ColorsEnum.coolgray5,
      }}
    >
      <ReportProblemIcon color="warning" />
      <Typography variant="body3">
        An Error Occurred when simulating our data. Please try again with some
        slightly different settings!
      </Typography>
    </div>
  );
}
