import React from "react";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { ColorsEnum } from "../../../../../common/theme";
import { Skeleton, Typography } from "@mui/material";
import useSimulatorStore from "../../../../../store/simulator";
import { ChartData, getChartData } from "../../SimulationPlayground/requests";
import { Drivers } from "../../../../../common/requests/types";

const generateDriverData = (
  drivers: Drivers,
  data: ChartData[],
  quadrant: number
) => {
  const categoryLabels: string[] = [];
  const neg: number[] = [];
  const pos: number[] = [];

  data.forEach((value) => {
    categoryLabels.push(drivers[value.driver_id].name);
    const scenarioValue: number = value[
      quadrant as keyof typeof value
    ] as number;
    if (scenarioValue < 0) {
      pos.push(0);
      neg.push(scenarioValue);
    } else {
      pos.push(scenarioValue);
      neg.push(0);
    }
  });

  let arrVals: { label: string; neg: number; pos: number }[] =
    categoryLabels.map((label, index) => ({
      label: label,
      neg: neg[index],
      pos: pos[index],
    }));

  if (arrVals.length > 10) arrVals = arrVals.slice(0, 10);

  arrVals.sort((a, b) => {
    const itemNow = a.pos === a.neg ? a.neg : a.pos === 0 ? a.neg : a.pos;
    const itemPrev = b.pos === b.neg ? b.neg : b.pos === 0 ? b.neg : b.pos;
    return Math.abs(itemPrev) - Math.abs(itemNow);
  });

  const sortedLabels = arrVals.map((item) => item.label);
  const sortedNeg = arrVals.map((item) => Math.round(item.neg * 100) / 100);
  const sortedPos = arrVals.map((item) => Math.round(item.pos * 100) / 100);

  var barCount = sortedLabels.length;
  var pointWidth = 20;
  var marginTop = 60;
  var marginBottom = 50;

  var chartHeight = marginTop + marginBottom + pointWidth * barCount;

  return {
    accessibility: {
      enabled: false,
    },
    chart: {
      type: "bar",
      height: chartHeight,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: "",
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      series: {
        dataLabels: {
          enabled: false,
        },
        pointPadding: 0,
        groupPadding: 0.1,
        pointWidth: 20, //width of the bar/column/point.
      },
    },
    xAxis: {
      categories: sortedLabels,
      lineWidth: 0,
      labels: {
        enabled: true,
      },
    },
    yAxis: [
      {
        width: "50%",
        reversed: false,
        title: {
          text: "",
        },
        min: -100,
        max: 0,
        gridLineWidth: 0,
      },
      {
        width: "50%",
        left: "50%",
        title: {
          text: "",
        },
        max: 100,
        min: 0,
        offset: 0,
        gridLineWidth: 0,
      },
    ],
    series: [
      {
        data: sortedNeg,
        yAxis: 0,
        color: ColorsEnum.negative,
      },
      {
        data: sortedPos,
        yAxis: 1,
        color: ColorsEnum.positive,
      },
    ],
  };
};

interface BidirectionalChartProps {
  drivers: Drivers;
  scenarioSelected?: string;
  scenarioQuadrantSelected?: number;
  selectedDimension?: string;
}

const BidirectionalChart = (props: BidirectionalChartProps) => {
  const [loading, selectedDrivers] = useSimulatorStore((state) => [
    state.simulatorResults.loading,
    state.selectedDrivers,
  ]);
  const [driverImpact, setDriverImpact] = React.useState<ChartData[]>([]);
  React.useEffect(() => {
    getChartData({
      drivers: props.drivers,
      selected_drivers: selectedDrivers,
    }).then((res) => {
      setDriverImpact(res.data);
    });
  }, [loading]);
  return props.selectedDimension ? (
    <div style={{ width: "100%" }}>
      <Typography variant="subtitle2" style={{ paddingBottom: 10 }}>
        {" "}
        Driver impact score (0-100) to{" "}
        <b>
          {" "}
          {
            props.drivers[props.selectedDimension as keyof typeof props.drivers]
              .name
          }{" "}
        </b>
        in <b> {props.scenarioSelected}</b> scenario
      </Typography>
      {loading || !props.scenarioQuadrantSelected ? (
        <Skeleton variant="rectangular" height={500} />
      ) : (
        <HighchartsReact
          highcharts={Highcharts}
          options={generateDriverData(
            props.drivers,
            driverImpact.filter(
              (value) => value.output_id === props.selectedDimension
            ),
            props.scenarioQuadrantSelected
          )}
        />
      )}
    </div>
  ) : null;
};

export default BidirectionalChart;
