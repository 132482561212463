const fontFamily = `"Inter", sans-serif`;

export const typographyTheme = {
  fontFamily: fontFamily,
  h1: {
    fontSize: 60,
    fontWeight: 400,
    lineHeight: "72px",
    letterSpacing: -1.2,
  },
  h2: {
    fontSize: 48,
    fontWeight: 400,
    lineHeight: "60px",
    letterSpacing: -0.96,
  },
  h3: {
    fontSize: 36,
    fontWeight: 400,
    lineHeight: "44px",
    letterSpacing: -0.72,
  },
  h4: {
    fontSize: 30,
    fontWeight: 400,
    lineHeight: "38px",
    letterSpacing: 0,
  },
  h5: {
    fontSize: 24,
    fontWeight: 400,
    lineHeight: "32px",
    letterSpacing: 0,
  },
  h6: {
    fontSize: 20,
    fontWeight: 500,
    lineHeight: "30px",
    letterSpacing: 0,
  },
  subtitle1: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: "28px",
    letterSpacing: 0,
  },
  subtitle2: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "22px",
    letterSpacing: 0,
  },
  body1: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: "24px",
    letterSpacing: 0,
  },
  body2: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: "20px",
    letterSpacing: 0,
  },
  body3: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: "18px",
    letterSpacing: "0.12px",
  },
  body4: {
    fontSize: 10,
    fontWeight: 400,
    lineHeight: "16px",
    letterSpacing: 0.12,
  },
  caption: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: "18px",
    letterSpacing: 0.12,
  },
  overline: {
    fontSize: 12,
    fontWeight: 600,
    letterSpacing: 1,
  },
  button: {
    variants: [
      {
        props: { size: "small" },
        style: {
          fontSize: "13px",
        },
      },
    ],
  },
};
