import * as React from "react";
import * as S from "./style";

import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Box, Button, Grid, Skeleton, Slider } from "@mui/material";
import { AuthContext } from "../../providers/AuthProvider";
import { ColorsEnum } from "../../common/theme";
import SynthesisLogo from "../../assets/logo-full.png";
import { getProjectOverview } from "../../common/requests/queries";
import { ProjectOverview } from "../../common/requests/types";

interface SideDrawerProps {
  iters: number;
  setIters: (v: number) => void;
}

export default function SideDrawer(props: SideDrawerProps) {
  const [open, setOpen] = React.useState(true);
  const { user, logout } = React.useContext(AuthContext)!;
  const [loading, setLoading] = React.useState<boolean>(false);
  const [value, setValue] = React.useState<ProjectOverview>();

  const defaultIterations = 1000;

  React.useEffect(() => {
    setLoading(true);
    getProjectOverview()
      .then((res) => {
        setValue(res.data);
        setLoading(false);
      })
      .catch((error) => {});
  }, []);

  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <S.Drawer
      variant="permanent"
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: ColorsEnum.coolgray5,
        },
      }}
    >
      <S.DrawerHeader>
        <div
          style={{
            display: open ? "flex" : "none",
            alignItems: "flex-start",
            width: "100%",
            padding: 15,
          }}
        >
          <img src={SynthesisLogo} width="100px" />
        </div>
        <IconButton onClick={handleToggle} disableRipple>
          {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </S.DrawerHeader>
      <Grid
        container
        style={{ padding: 24, paddingTop: 10, gap: 15 }}
        display={open ? "flex" : "none"}
        justifyContent="center"
        flexDirection="column"
      >
        {loading ? (
          <React.Fragment>
            <Skeleton variant="rectangular" width="100%" height={50} />
            <Skeleton variant="rectangular" height="200px" width="100%" />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Typography variant="h6"> {value?.project_name} </Typography>
            <Typography
              variant="body3"
              sx={{
                overflow: "auto",
                maxHeight: "250px",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
              }}
            >
              {value?.about}
            </Typography>
          </React.Fragment>
        )}
        <Divider />
        <Typography variant="overline">advanced options</Typography>
        <Typography variant="subtitle2">Number of Simulations</Typography>
        <Typography variant="body3">
          More simulations means greater accuracy. Fewer means faster loading
          times. We recommend running 10,000 simulations.
        </Typography>
        <div style={{ paddingBottom: 75 }}>
          <Typography variant="body3">
            Selected <b>{props.iters}</b> iterations
            {props.iters === defaultIterations ? " (default)." : "."}
          </Typography>
          <Slider
            defaultValue={defaultIterations}
            getAriaValueText={(value: number) => `${value} iterations`}
            step={500}
            marks
            min={500}
            max={10000}
            valueLabelDisplay="auto"
            onChange={(event: any) => props.setIters(event?.target?.value)}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: -0.5,
            }}
          >
            <Typography variant="body3">500</Typography>
            <Typography variant="body3">10,000</Typography>
          </Box>
        </div>
        <Button onClick={logout} variant="outlined">
          <Typography variant="subtitle2"> Logout </Typography>
        </Button>
      </Grid>
    </S.Drawer>
  );
}
