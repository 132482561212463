export const ENDPOINTS = {
  BASE_QUERY_ENDPOINT: "/query",
  SCENARIO_QUERY_ENDPOINT: "/query/scenarios",
  DRIVERS_QUERY_ENDPOINT: "/query/drivers",
  CASUALRELATIONSHIPS_QUERY_ENDPOINT: "/query/causalrelationships",
  PROXIES_QUERY_ENDPOINT: "/query/proxies",
  DRIVER_TIMESERIES_QUERY_ENDPOINT: "/query/driver/timeseries",
  LATEST_DRIVER_TIMESERIES_QUERY_ENDPOINT: "/query/driver/latest-timeseries",
  // SIMULATOR ENDPOINTS
  CHART_DATA_ENDPOINT: "/simulate/chart-data",
  SIMULATE_ENDPOINT: "/simulate",
};
