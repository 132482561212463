import * as React from "react";
import * as S from "./style";
import { Button, Grid, Skeleton, Tooltip, Typography } from "@mui/material";
import DriverPanel from "./DriverPanel/DriverPanel";
import DriverCard from "./DriverCard";
import { TEXTS } from "../../../../common/texts";
import { SimulationPlaygroundProps } from "./types";
import { runSimulation } from "./requests";
import useSimulatorStore from "../../../../store/simulator";
import { getAllDriverTimeSeries } from "../../../../common/requests/queries";
import { DriverTimeSeries } from "../../../../common/requests/types";
import { DriverRangeType } from "../../../../store/simulator/simulator";

export default function SimulationPlayground(props: SimulationPlaygroundProps) {
  const [dataLoading, setDataLoading] = React.useState<boolean>(false);
  const [driverTimeSeries, setDriverTimeSeries] = React.useState<
    DriverTimeSeries[]
  >([]);
  const [
    setSimulatorResults,
    setLoading,
    setInitialised,
    selectedDrivers,
    setSelectedDrivers,
  ] = useSimulatorStore((state) => [
    state.setSimulatorResults,
    state.setLoading,
    state.setInitialised,
    state.selectedDrivers,
    state.setSelectedDrivers,
  ]);

  React.useEffect(() => {
    setDataLoading(true);
    getAllDriverTimeSeries(Object.keys(props.drivers))
      .then((res) => {
        setDriverTimeSeries(res.data);
        setDataLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <Grid container style={{ padding: "20px 5px" }}>
      <Grid container>
        <Typography variant="h6">Simulation Playground</Typography>
      </Grid>
      <Grid container>
        <Typography variant="body2">{TEXTS.SIMULATOR_DESCRIPTION}</Typography>
      </Grid>
      <Grid container spacing={2} style={{ paddingTop: "20px" }}>
        <Grid item xs={4}>
          <Typography variant="subtitle1" style={{ paddingBottom: "10px" }}>
            All Drivers
          </Typography>
          {props.loading ? (
            <Skeleton width="100%" height={500} variant="rectangular" />
          ) : (
            <S.StyledDriverList container>
              <DriverPanel drivers={Object.values(props.drivers)} />
            </S.StyledDriverList>
          )}
        </Grid>
        <Grid item xs={8}>
          <Grid
            container
            style={{ paddingBottom: "10px" }}
            display="flex"
            justifyContent="flex-end"
            gap={1}
          >
            <div>
              <S.StyledButton
                size="small"
                color="secondary"
                variant="outlined"
                disabled
              >
                💭 Inspire me
              </S.StyledButton>
            </div>
            <div>
              <Tooltip title="Clear All Selected Drivers">
                <span>
                  <S.StyledButton
                    disabled={Object.keys(selectedDrivers).length === 0}
                    size="small"
                    color="secondary"
                    variant="outlined"
                    onClick={() => setSelectedDrivers({})}
                  >
                    🍃 Start fresh
                  </S.StyledButton>
                </span>
              </Tooltip>
            </div>
          </Grid>
          <S.StyledDriverContainer container gap={2}>
            <Grid container style={{ width: "100%" }} flex={1} gap={2}>
              {Object.keys(selectedDrivers).length === 0 ? (
                <S.EmptyTextbox>
                  <Typography variant="subtitle2" align="center">
                    {" "}
                    {TEXTS.EMPTY_DRIVER_PANEL_PROMPT}{" "}
                  </Typography>
                </S.EmptyTextbox>
              ) : null}
              {Object.keys(selectedDrivers).map((driverId: string) => (
                <DriverCard
                  key={driverId}
                  driver={props.drivers[driverId]}
                  onChange={(driverId: string, value: DriverRangeType) =>
                    setSelectedDrivers({
                      ...selectedDrivers,
                      [driverId]: value,
                    })
                  }
                  onClose={() => {
                    const newDriver = { ...selectedDrivers };
                    delete newDriver[driverId];
                    setSelectedDrivers(newDriver);
                  }}
                />
              ))}
            </Grid>
            <Button
              variant="contained"
              fullWidth
              style={{ height: "40px" }}
              disabled={dataLoading}
              onClick={() => {
                setLoading(true);
                setInitialised(true);
                const payload = {
                  num_iters: props.iters,
                  driver_relationships: props.driverRelationships,
                  drivers: props.drivers,
                  proxies: props.proxies,
                  selected_drivers: selectedDrivers,
                  driver_timeseries: driverTimeSeries,
                };
                runSimulation(payload).then((res) => {
                  const scenarioLikelihoods = res.data;
                  console.log(scenarioLikelihoods)
                  if (scenarioLikelihoods)
                  props.setSelectedCategory(props.scenarios[
                    Number(
                      Object.keys(
                        scenarioLikelihoods.data.scenario_weightages
                      )?.reduce((a: any, b: any) =>
                        scenarioLikelihoods.data.scenario_weightages[a] >
                        scenarioLikelihoods.data.scenario_weightages[b]
                          ? a
                          : b
                      )
                    ) - 1
                  ].name)
                  setSimulatorResults({
                    error: scenarioLikelihoods.status === "error",
                    scenarioLikelihoods: scenarioLikelihoods.data,
                    loading: false,
                    timeStamp: new Date(),
                  });
                });
              }}
            >
              {dataLoading
                ? "Preparing Simulation Data..."
                : Object.keys(selectedDrivers).length === 0
                ? "Run Simulation with Default Values"
                : "Run Simulation"}
            </Button>
          </S.StyledDriverContainer>
        </Grid>
      </Grid>
    </Grid>
  );
}
