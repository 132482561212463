import * as React from "react";
import { Grid, Typography } from "@mui/material";
import useSimulatorStore from "../../../../../store/simulator";
import { TEXTS } from "../../../../../common/texts";

export default function SimulatorResultsHeader() {
  const simulatorResults = useSimulatorStore((state) => state.simulatorResults);
  return (
    <Grid container display="flex" flexDirection="column">
      <Typography variant="h6">Simulator Results</Typography>
      <Typography variant="body3">
        {simulatorResults.loading ? (
          <LoadingDots />
        ) : simulatorResults.timeStamp ? (
          `Last Generated: ${simulatorResults.timeStamp.toDateString()}, ${simulatorResults.timeStamp.toLocaleTimeString()}`
        ) : null}{" "}
      </Typography>
    </Grid>
  );
}

const LoadingDots = () => {
  const [dots, setDots] = React.useState(0);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setDots((dots) => (dots + 1) % 4);
    }, 300); // Change the interval duration to speed up or slow down the animation

    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <span>
        {TEXTS.SIMULATION_LOADING}
        {Array.from({ length: dots + 1 }, (_, index) => ".").join("")}
      </span>
    </div>
  );
};
