import { styled } from "@mui/material";
import { ColorsEnum } from "../../common/theme";

const borderRadius = 5;

const leftBorderStyling = {
  borderTopLeftRadius: borderRadius,
  borderBottomLeftRadius: borderRadius,
};

export const ProgressBarWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  height: "20px",
  minWidth: "100px",
  borderRadius: borderRadius,
}));

export const BenchmarkFill = styled("div")(({ theme }) => ({
  backgroundColor: ColorsEnum.warmgray2,
  ...leftBorderStyling,
}));

export const DefaultFill = styled("div")(({ theme }) => ({
  backgroundColor: ColorsEnum.primary,
  ...leftBorderStyling,
}));

export const RemainderFill = styled("div")(({ theme }) => ({
  backgroundColor: ColorsEnum.coolgray5,
  borderTopRightRadius: borderRadius,
  borderBottomRightRadius: borderRadius,
  paddingRight: 10,
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
}));
