import * as React from "react";
import { Navigate } from "react-router-dom";
import { ROUTES } from "../../../common/routes";
import { AuthContext } from "../../../providers/AuthProvider/AuthProvider";
import { checkWhitelisted } from "../../../common/firebase/auth";

export default function Private({ FC }: any) {
  const { user } = React.useContext(AuthContext)!;
  if (user && checkWhitelisted(user.providerData[0].email)) {
    return <FC />;
  } else {
    return <Navigate to={ROUTES.SIGNIN} />;
  }
}
