import * as React from "react";
import * as d3 from "d3";
import { ColorsEnum, hexToRGBA } from "../../../../../common/theme";
import { getDriverTimeseries } from "../../../../../common/requests/queries";
import { DriverTimeSeries } from "../../../../../common/requests/types";
import { Skeleton, Typography } from "@mui/material";
import { TEXTS } from "../../../../../common/texts";

interface LineChartProps {
  driverId: string;
  status: any;
  setBoundValue: (val: number) => void;
}

const margin = { top: 20, left: 10, right: 10, bottom: 20 };
const width = 280;
const height = 150;
const axisColor = ColorsEnum.coolgray6;

const DriverChart = ({ driverId, status, setBoundValue }: LineChartProps) => {
  const svgRef = React.useRef<SVGSVGElement>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [data, setData] = React.useState<DriverTimeSeries[]>([]);

  React.useEffect(() => {
    setLoading(true);
    getDriverTimeseries(driverId).then((res) => {
      setData(res.data);
      setLoading(false);
    });
  }, [driverId]);

  React.useEffect(() => {
    if (!data.length) return;

    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove(); // Clear the SVG

    // Setup scales and axes
    const { xScale, yScale } = setupScales(data);

    // Filter out the values that has upper and lower bounds
    const dat = data.filter(
      (d) => d.lower_bound !== null && d.upper_bound !== null
    );

    // Plotting functions
    plotLine(
      svg,
      data.slice(0, data.length - dat.length + 1),
      xScale,
      yScale,
      ColorsEnum.primary
    );
    plotArea(svg, dat, xScale, yScale, "upper", ColorsEnum.primary, 0.6);
    plotArea(svg, dat, xScale, yScale, "lower", ColorsEnum.white, 1);

    plotCurrentYearLine(svg, dat[0].year, xScale);
    plotLine(svg, dat, xScale, yScale, ColorsEnum.white, "dash");
    plotCircle(svg, data, dat, xScale, yScale, status, setBoundValue);
    renderAxes(svg, xScale, yScale);

    const minYear = data[0].year;
    const maxYear = data[data.length - 1].year;
    plotText(svg, xScale(minYear) - margin.right, height, String(minYear));
    plotText(svg, xScale(maxYear) + margin.left, height, String(maxYear));
    plotText(svg, xScale(dat[0].year), margin.top, String(dat[0].year));
  }, [data, status]);

  return loading ? (
    <Skeleton variant="rectangular" height={"100%"} />
  ) : data.length !== 0 ? (
    <svg
      ref={svgRef}
      width="100%"
      height="100%"
      viewBox={`0 0 ${width} ${height}`}
      preserveAspectRatio="xMidYMid meet"
    />
  ) : (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        borderRadius: "10px",
        height: height,
        backgroundColor: ColorsEnum.coolgray5,
        color: ColorsEnum.coolgray2,
      }}
    >
      <Typography variant="subtitle2">
        {TEXTS.DRIVER_NO_DATA_AVAILABLE}
      </Typography>
    </div>
  );
};

function setupScales(data: DriverTimeSeries[]) {
  let minYear: number = data[0].year;
  let maxYear: number = data[data.length - 1].year;
  let minValue: number = data[0].lower_bound;
  let maxValue: number = data[0].upper_bound;

  for (let entry of data) {
    minYear = Math.min(minYear, entry.year);
    maxYear = Math.max(maxYear, entry.year);
    minValue = Math.min(minValue, entry.lower_bound, entry.value);
    maxValue = Math.max(maxValue, entry.upper_bound, entry.value);
  }

  const xScale = d3
    .scaleLinear()
    .domain([minYear, maxYear])
    .range([margin.left, width - margin.right]);
  const yScale = d3
    .scaleLinear()
    .domain([minValue, maxValue])
    .range([height - margin.bottom, margin.top]);

  return { xScale, yScale };
}

function renderAxes(svg: any, xScale: any, yScale: any) {
  const xAxis = d3.axisBottom(xScale).ticks(0).tickSizeOuter(0);
  const yAxis = d3.axisLeft(yScale).ticks(0).tickSizeOuter(0);

  svg
    .append("g")
    .attr("transform", `translate(0, ${height - margin.bottom})`)
    .call(xAxis)
    .attr("color", axisColor);
  svg
    .append("g")
    .attr("transform", `translate(${margin.left}, 0)`)
    .call(yAxis)
    .attr("color", axisColor);

  svg
    .append("path")
    .attr(
      "d",
      "M" +
        (width - margin.right - 10) +
        " " +
        (height - margin.bottom - 5) +
        " L" +
        (width - margin.right) +
        " " +
        (height - margin.bottom) +
        " L" +
        (width - margin.right - 10) +
        " " +
        (height - margin.bottom + 5) +
        " Z"
    )
    .attr("fill", axisColor);

  svg
    .append("path")
    .attr(
      "d",
      "M" +
        (margin.left - 5) +
        " " +
        (margin.top + 10) +
        " L" +
        margin.left +
        " " +
        margin.top +
        " L" +
        (margin.left + 5) +
        " " +
        (margin.top + 10) +
        " Z"
    )
    .attr("fill", axisColor);
}

function plotLine(
  svg: any,
  data: DriverTimeSeries[],
  xScale: any,
  yScale: any,
  color: string,
  type?: string
) {
  const line = d3
    .line<DriverTimeSeries>()
    .x((d) => xScale(d.year))
    .y((d) => yScale(d.value));
  svg
    .append("path")
    .datum(data)
    .attr("d", line)
    .attr("fill", "none")
    .attr("stroke", color)
    .attr("stroke-width", "2px")
    .attr("stroke-dasharray", type === "dash" ? "5,5" : "0");
}

function plotArea(
  svg: any,
  dat: DriverTimeSeries[],
  xScale: any,
  yScale: any,
  type: "upper" | "lower",
  fill: string,
  opacity: number
) {
  const area = d3
    .area<DriverTimeSeries>()
    .x((d) => xScale(d.year))
    .y0((d) => yScale(type === "upper" ? d.upper_bound : d.lower_bound))
    .y1(height - margin.bottom);
  svg
    .append("path")
    .attr("id", "area")
    .datum(dat)
    .attr("d", area)
    .attr("fill", hexToRGBA(fill, opacity));
}

function plotCurrentYearLine(svg: any, currentYear: number, xScale: any) {
  const lineX = xScale(currentYear);

  svg
    .append("line")
    .attr("x1", lineX)
    .attr("x2", lineX)
    .attr("y1", margin.top)
    .attr("y2", height - margin.bottom)
    .attr("stroke", "red")
    .attr("stroke-width", 1)
    .attr("stroke-dasharray", "3 3");
}

function plotText(svg: any, x: any, y: any, text: string) {
  svg
    .append("text")
    .attr("x", x)
    .attr("y", y)
    .text(text)
    .attr("dy", -5) // Adjust the vertical offset of the text (optional)
    .attr("text-anchor", "middle") // Center the text horizontally (optional)
    .attr("fill", ColorsEnum.secondary) // Color of the text (optional)
    .attr("font-weight", "bold")
    .attr("font-size", "12px");
}

function plotCircle(
  svg: any,
  data: DriverTimeSeries[],
  dat: DriverTimeSeries[],
  xScale: any,
  yScale: any,
  status: any,
  setBoundValue: (val: number) => void
) {
  const circleRadius = 5;

  const finalDate = xScale(data[data.length - 1].year);
  const lowest = dat[dat.length - 1].lower_bound;
  const medium = data[data.length - 1].value; // Our final value
  const highest = dat[dat.length - 1].upper_bound;
  const lower = (lowest + medium) / 2;
  const higher = (highest + medium) / 2;

  let scaleValue = medium;

  if (status === "very low") scaleValue = lowest;
  else if (status === "low") scaleValue = lower;
  else if (status === "high") scaleValue = higher;
  else if (status === "very high") scaleValue = highest;

  const coords = [finalDate, yScale(scaleValue)];

  setBoundValue(Math.abs(scaleValue) / medium);

  svg
    .append("circle")
    .attr("cx", coords[0])
    .attr("cy", coords[1])
    .attr("r", circleRadius)
    .attr("fill", ColorsEnum.darkGrey);
}

export default DriverChart;
