import * as React from "react";
import { Grid } from "@mui/material";
import UpdatedScenarios from "./UpdatedScenarios/UpdatedScenarios";
import CategoryDimensions from "./CategoryDimensions";
import { Drivers, Scenario } from "../../../../common/requests/types";
import SimulatorResultsHeader from "./SimulatorResultsHeader/SimulatorResultsHeader";
import BidirectionalChart from "./BidirectionalChart";
import useSimulatorStore from "../../../../store/simulator";

interface SimulatorResultsProps {
  drivers: Drivers;
  scenarios: Scenario[];
  selectedCategory?: string;
  setSelectedCategory: (s: string) => void;
}

export default function SimulatorResults(props: SimulatorResultsProps) {
  const [selectedDimension, setSelectedDimension] = React.useState<
    string | undefined
  >();

  return (
    <Grid container style={{ padding: "20px 5px" }} gap={3}>
      <React.Fragment>
        <SimulatorResultsHeader />
      <UpdatedScenarios scenarios={props.scenarios} />
      <CategoryDimensions
        selectedDimension={selectedDimension}
        selectedCategory={props.selectedCategory}
        setSelectedCategory={props.setSelectedCategory}
        setSelectedDimension={setSelectedDimension}
        scenarios={props.scenarios}
        drivers={props.drivers}
      />
      <BidirectionalChart
        selectedDimension={selectedDimension}
        drivers={props.drivers}
        scenarioQuadrantSelected={
          props.selectedCategory ? props.scenarios.find((value) => value.name === props.selectedCategory)!
            .quadrant : undefined
        }
        scenarioSelected={props.selectedCategory}
      />
      </React.Fragment>
    </Grid>
  );
}
