import { Grid, styled } from "@mui/material";
import { ColorsEnum } from "../../../../common/theme";

export const StyledGrid = styled(Grid)(({ theme }) => ({
  minHeight: "100px",
  padding: "15px 20px",
  width: "100%",
  border: `1px solid ${ColorsEnum.coolgray6}`,
  borderRadius: "8px",
}));
