// https://colordesigner.io/gradient-generator
export function hexToRGBA(hex: string, alpha: number) {
  const r = parseInt(hex.substring(1, 3), 16);
  const g = parseInt(hex.substring(3, 5), 16);
  const b = parseInt(hex.substring(5, 7), 16);
  return `rgba(${r},${g},${b},${alpha})`;
}
export enum ColorsEnum {
  primary = "#FF5208",
  secondary = "#424242",
  success = "#85B899",
  error = "#ff3333",
  sunshine = "#FFDA00",
  tangerine = "#FF5208",
  tangerine0 = "#DA4606",
  tangerine1 = "#FF6A2B",
  tangerine2 = "#FF834E",
  tangerine3 = "#FF9C71",
  tangerine4 = "#FFB495",
  tangerine5 = "#FFCDB8",
  salmon = "#F5734D",
  blush = "#FFBAAB",
  bone = "#FFEDDE",
  bone1 = "#FFF6EE",
  lavender = "#A37DFC",
  amethyst = "#6100FA",
  field = "#85B899",
  mint = "#85E8AD",
  azure = "#DBFAFA",
  azure1 = "#EDFCFC",
  gunmetal = "#333333",
  black = "#000000",
  warmgray1 = "#55524F",
  warmgray2 = "#77716C",
  warmgray3 = "#999088",
  warmgray4 = "#BBAFA5",
  warmgray5 = "#DDCEC1",
  coolgray1 = "#4F5454",
  coolgray2 = "#6B7575",
  coolgray3 = "#879696",
  coolgray4 = "#A3B7B7",
  coolgray5 = "#F5F5F5",
  coolgray6 = "#E0E0E0",
  coolgray7 = "#F9F9F9",
  white = "#FFFFFF",
  mamitaGreen = "#26D221",
  geekBlue = "#3D5884",
  intellectualLavender = "#774161",
  teenPurple = "#8A02E5",
  mainstreamBlue = "#00D2FF",
  machoBlue = "#4169E1",
  seeAndBeGreen = "#00C89B",
  memeBlue = "#76ABFF",
  youthPink = "#FF00FF",
  gamerKhaki = "#C8BFA8",
  oldschoolOrange = "#F3790D",
  mainstreamYellow = "#FFCC00",
  facebook = "#4267B2",
  rain = "#82B3ED",
  twitter = "#1DA1F2",
  negative = "#FF5048",
  positive = "#3D9971",
  instagram = "#C13584",
  darkGrey = "#2b2b2b",
  darkMode = "#121212",
}
