import * as React from "react";
import * as S from "./style";
import { Grid, Skeleton, Typography } from "@mui/material";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import {
  Driver,
  Drivers,
  Scenario,
} from "../../../../../common/requests/types";
import {
  TEXTS,
  formatNumCommas,
  formatNumSuffixes,
} from "../../../../../common/texts";
import useSimulatorStore from "../../../../../store/simulator";
import { ColorsEnum } from "../../../../../common/theme";
import { ScenarioForecasts } from "../../../../../store/simulator/simulator";
import ErrorOccurred from "../ErrorOccurred";

interface CategoryDimensionsCardProps {
  driver: Driver;
  scenarioQuadrant: 1 | 2 | 3 | 4;
  setSelectedDimension: (name: undefined | string) => void;
  selectedDimension: string | undefined;
  forecasts: { cagr: ScenarioForecasts; vol: ScenarioForecasts };
}

const CategoryDimensionCard = (props: CategoryDimensionsCardProps) => {
  const baseScenarioCAGR = props.forecasts.cagr.basecase;
  const quadrantScenarioCAGR = props.forecasts.cagr[props.scenarioQuadrant];
  const baseScenarioVolume = props.forecasts.vol.basecase;
  const quadrantScenarioVolume = props.forecasts.vol[props.scenarioQuadrant];

  const isZero = quadrantScenarioCAGR.mid - baseScenarioCAGR.mid === 0;
  const upTrend = quadrantScenarioCAGR.mid > baseScenarioCAGR.mid;
  const color = upTrend ? ColorsEnum.positive : ColorsEnum.negative;

  return (
    <Grid item lg={4} md={4} xs={6}>
      <S.StyledCard
        onClick={() =>
          isZero
            ? props.setSelectedDimension(undefined)
            : props.setSelectedDimension(props.driver.driver_id)
        }
        style={{ backgroundColor: props.selectedDimension === props.driver.driver_id ? ColorsEnum.coolgray5 : 'transparent'}}
      >
        {isZero ? (
          <PriorityHighIcon />
        ) : upTrend ? (
          <TrendingUpIcon sx={{ color: color }} />
        ) : (
          <TrendingDownIcon sx={{ color: color }} />
        )}
        <Grid container sx={{ paddingBottom: 1 }}>
          <Typography variant="subtitle2" noWrap>
            {props.driver.name}
          </Typography>
        </Grid>
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="body3" sx={{ color: ColorsEnum.secondary }}>
              {" "}
              CAGR{" "}
            </Typography>
            <Grid
              container
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              gap={0.5}
            >
              <Typography variant="subtitle1">
                {(quadrantScenarioCAGR.mid * 100).toFixed(2)}%
              </Typography>
              <Typography variant="body3" sx={{ color: color }}>
                {isZero
                  ? null
                  : `${upTrend ? "+" : "-"} ${(
                      Math.abs(
                        quadrantScenarioCAGR.mid - baseScenarioCAGR.mid
                      ) * 100
                    ).toFixed(2)}%`}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body3" sx={{ color: ColorsEnum.secondary }}>
              {" "}
              Volume{" "}
            </Typography>
            <Grid
              container
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              gap={0.5}
            >
              <Typography variant="subtitle1">
                {formatNumCommas(quadrantScenarioVolume.mid)}
              </Typography>
              <Typography variant="body3" sx={{ color: color }}>
                {isZero
                  ? null
                  : `${upTrend ? "+" : "-"}${formatNumSuffixes(
                      Math.abs(
                        quadrantScenarioVolume.mid - baseScenarioVolume.mid
                      )
                    )}`}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </S.StyledCard>
    </Grid>
  );
};

interface CategoryDimensionsProps {
  selectedCategory?: string;
  setSelectedCategory: (name: string) => void;
  selectedDimension?: string;
  setSelectedDimension: (name: string | undefined) => void;
  scenarios: Scenario[];
  drivers: Drivers;
}

export default function CategoryDimensions(props: CategoryDimensionsProps) {
  const simulatorResults = useSimulatorStore((state) => state.simulatorResults);
  return (
    <Grid container display="flex" flexDirection="column">
      <Typography variant="subtitle1">Category Dimensions</Typography>
      <Typography variant="body3">
        {" "}
        {TEXTS.CATEGORY_DIMENSION_DESCRIPTION}{" "}
      </Typography>
      <Stack direction="row" spacing={0.5} sx={{ paddingTop: 3 }}>
        {props.scenarios.map((scenario) => (
          <Chip
            key={scenario.scenario_id}
            label={scenario.name}
            color="secondary"
            onClick={() => {
              props.setSelectedCategory(scenario.name);
              props.setSelectedDimension(undefined);
            }}
            variant={
              props.selectedCategory === scenario.name ? "filled" : "outlined"
            }
          />
        ))}
      </Stack>
      {simulatorResults.error ? (
        <ErrorOccurred height={500} />
      ) : simulatorResults.loading ? (
        <div style={{ paddingTop: 20 }}>
          <Skeleton variant="rectangular" animation="wave" height={500} />
        </div>
      ) : (
        <Grid container sx={{ paddingTop: 3 }} spacing={2}>
          {simulatorResults.scenarioLikelihoods &&
          simulatorResults.scenarioLikelihoods.forecasts
            ? Object.keys(simulatorResults.scenarioLikelihoods.forecasts).map(
                (driverId: string) => {
                  const quadrant = props.scenarios.find(
                    (obj) => obj.name === props.selectedCategory
                  )?.quadrant;
                  return quadrant ? (
                    <CategoryDimensionCard
                    selectedDimension={props.selectedDimension}
                      setSelectedDimension={props.setSelectedDimension}
                      driver={props.drivers[driverId]}
                      scenarioQuadrant={quadrant as 1 | 2 | 3 | 4}
                      forecasts={
                        simulatorResults.scenarioLikelihoods.forecasts[driverId]
                      }
                      key={`${driverId}-categoryDimensionCard`}
                    />
                  ) : null;
                }
              )
            : null}
        </Grid>
      )}
    </Grid>
  );
}
