import { create } from "zustand";

interface ForecastRanges {
  lower: number;
  mid: number;
  upper: number;
}

export interface ScenarioForecasts {
  1: ForecastRanges;
  2: ForecastRanges;
  3: ForecastRanges;
  4: ForecastRanges;
  basecase: ForecastRanges;
}

interface ScenarioLikelihoodType {
  scenario_weightages: { [scenarioNumber: number]: number };
  forecasts: {
    [driverId: string]: { cagr: ScenarioForecasts; vol: ScenarioForecasts };
  };
}

interface SimulatorResultsType {
  error: boolean;
  scenarioLikelihoods: ScenarioLikelihoodType;
  loading: boolean;
  timeStamp: Date; // The time the latest simulation is completed (and ran)
}

export type DriverRangeType =
  | "very low"
  | "low"
  | "medium"
  | "high"
  | "very high";

export interface SelectedDrivers {
  [driverId: string]: DriverRangeType;
}

interface SimulatorType {
  selectedDrivers: SelectedDrivers;
  setSelectedDrivers: (selectedDrivers: SelectedDrivers) => void;
  initialised: boolean;
  setInitialised: (initialised: boolean) => void;
  setLoading: (loading: boolean) => void;
  simulatorResults: SimulatorResultsType;
  setSimulatorResults: (simulatorResults: SimulatorResultsType) => void;
}

export const useSimulatorStore = create<SimulatorType>((set) => ({
  selectedDrivers: {} as SelectedDrivers,
  setSelectedDrivers: (selectedDrivers: SelectedDrivers) =>
    set({
      selectedDrivers: selectedDrivers,
    }),
  initialised: false,
  setInitialised: (initialised: boolean) =>
    set({
      initialised: initialised,
    }),
  simulatorResults: {} as SimulatorResultsType,
  setLoading: (loading: boolean) =>
    set((state) => ({
      simulatorResults: { ...state.simulatorResults, loading: loading },
    })),
  setSimulatorResults: (simulatorResults?: SimulatorResultsType) => {
    set({ simulatorResults: simulatorResults });
  },
}));
