import * as React from "react";

import { Alert, Box, CssBaseline, Snackbar } from "@mui/material";
import { TEXTS } from "../../../common/texts";

export interface ContainerWrapperProps {
  children: React.ReactNode;
  hideNavigate?: boolean;
}

const CssStylesheets = () => (
  <React.Fragment>
    <link rel="preconnect" href="https://fonts.googleapis.com" />
    <link rel="preconnect" href="https://fonts.gstatic.com" />
    <link
      href="https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700&display=swap"
      rel="stylesheet"
    />
    <link rel="icon" href="/favicon.ico?v=2" />
  </React.Fragment>
);

const DisconnectAlert = () => {
  const connected = navigator.onLine;

  return !connected ? (
    <Snackbar open={true}>
      <Alert severity="error"> {TEXTS.OFFLINE} </Alert>
    </Snackbar>
  ) : null;
};

export default function ContainerWrapper(props: ContainerWrapperProps) {
  const [online, setOnline] = React.useState<boolean>(navigator.onLine);

  React.useEffect(() => {
    const handleStatusChange = () => setOnline(navigator.onLine);
    window.addEventListener("online", handleStatusChange);
    window.addEventListener("offline", handleStatusChange);
    return () => {
      window.removeEventListener("online", handleStatusChange);
      window.removeEventListener("offline", handleStatusChange);
    };
  }, [online]);

  return (
    <Box
      style={{
        height: "100vh",
        width: "100%",
        display: "flex",
        overflow: "hidden",
        maxWidth: "1800px",
        minWidth: "800px",
      }}
    >
      <CssStylesheets />
      <CssBaseline />
      {props.children}
      <DisconnectAlert />
    </Box>
  );
}
