export const whitelistedDomains = [
  "synthesis.partners",
  "lauren.praverman@pepsico.com",
  "laura.oliveira@pepsico.com",
  "paul.matchett@pepsico.com",
  "andrea.bingen@pepsico.com",
  "yikuang5@gmail.com",
  "yikuang@infiniwell.ai",
];

export class DomainNotWhitelistedError extends Error {
  constructor(message = "", ...args: any[]) {
    super(message, ...args);
    this.message =
      "Your domain has not been authorised to access the scenario simulator!";
  }
}

export const checkWhitelisted = (email: string | null) => {
  if (email && whitelistedDomains.includes(email.split("@")[1])) return true; // Check for whitelisted domain
  if (email && whitelistedDomains.includes(email.toLowerCase())) return true; // Check for actual email string
  return false;
};
