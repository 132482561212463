import { GridColDef } from "@mui/x-data-grid";

let columnDefinitions: GridColDef<any>[] = [
  { field: "id", headerName: "ID", width: 50 },
  {
    field: "market",
    headerName: "Market",
    width: 80,
    editable: false,
  },
  {
    field: "name",
    headerName: "Name",
    width: 200,
    editable: false,
  },
  {
    field: "category",
    headerName: "Category",
    width: 120,
    editable: false,
  },
  {
    field: "units",
    headerName: "Units",
    width: 100,
    editable: true,
  },
  {
    field: "description",
    headerName: "Description",
    width: 250,
  },
  {
    field: "dataSource",
    headerName: "Data Source",
    width: 120,
  },
  {
    field: "updateFrequency",
    headerName: "Update Freq",
    description: "This column has a value getter and is not sortable.",
    width: 120,
  },
];

export default columnDefinitions;
