import { styled } from "@mui/material";
import { ColorsEnum } from "../../../../../common/theme";

export const StyledCard = styled("div")(({ theme }) => ({
  height: "150px",
  padding: "15px 20px",
  width: "100%",
  border: `1px solid ${ColorsEnum.coolgray6}`,
  borderRadius: "8px",
  "&:hover": {
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    transform: 1.02,
    cursor: "pointer",
  },
}));
