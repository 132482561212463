import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ROUTES } from "./common/routes";
import Landing from "./pages/Landing";
import Private from "./components/Routes/Private";
import SignIn from "./pages/Authentication/SignIn/SignIn";
import PageNotFound from "./pages/PageNotFound/PageNotFound";

function App() {
  return (
    <Router>
      <Routes>
        <Route path={ROUTES.SIGNIN} element={<SignIn />} />
        <Route path={ROUTES.LANDING} element={<Private FC={Landing} />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
