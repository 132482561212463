import React from "react";
import { ContainerWrapper } from "../../components/Wrappers/ContainerWrapper";
import PageNotFoundLogo from "../../assets/page-not-found.png";
import { Button, Grid, Typography } from "@mui/material";
import SynthesisLogo from "../../assets/logo-full.png";
import { TEXTS } from "../../common/texts";
import { ColorsEnum } from "../../common/theme";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../common/routes";
export default function PageNotFound() {
  const navigate = useNavigate();
  return (
    <ContainerWrapper>
      <Grid container style={{ width: "100%", height: "100%", padding: 100 }}>
        <Grid item xs={8}>
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              paddingLeft: 50,
              flexDirection: "column",
              paddingTop: 100,
              gap: 20,
            }}
          >
            <img src={SynthesisLogo} width="200px" />
            <Typography variant="h3">
              {" "}
              <b>404.</b> Page Not Found{" "}
            </Typography>
            <Typography variant="body3"> {TEXTS.PAGE_404} </Typography>
            <Typography variant="body3" style={{ color: ColorsEnum.warmgray2 }}>
              {" "}
              {TEXTS.PAGE_404_SUB}{" "}
            </Typography>
            <div style={{ paddingTop: 20 }}>
              <Button
                onClick={() => navigate(ROUTES.LANDING)}
                startIcon={<KeyboardReturnIcon />}
                variant="contained"
              >
                {" "}
                Back Home{" "}
              </Button>
            </div>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div
            style={{
              paddingTop: 100,
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "center",
            }}
          >
            <img
              src={PageNotFoundLogo}
              height="300px"
              style={{ marginRight: 100 }}
            />
          </div>
        </Grid>
      </Grid>
    </ContainerWrapper>
  );
}
