// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  OAuthProvider,
} from "firebase/auth";
// https://firebase.google.com/docs/web/setup#available-libraries

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCvIyk29trVQwH4AYS6ZP58gWmZuoLAMqc",
  authDomain: "simulator-ce4a1.firebaseapp.com",
  projectId: "simulator-ce4a1",
  storageBucket: "simulator-ce4a1.appspot.com",
  messagingSenderId: "1064274430711",
  appId: "1:1064274430711:web:3e877ba024628654f0a1f2",
  measurementId: "G-6YFQZ4W6K8",
};

const googleProvider = new GoogleAuthProvider();
googleProvider.setCustomParameters({
  prompt: "select_account",
});
googleProvider.addScope("email");

const microsoftProvider = new OAuthProvider("microsoft.com");
microsoftProvider.setCustomParameters({
  prompt: "select_account",
});
microsoftProvider.addScope("email");
// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const firestore = getFirestore(app);
const auth = getAuth();
export const signInWithGooglePopup = () =>
  signInWithPopup(auth, googleProvider);
export const signInWithMicrosoftPopup = () =>
  signInWithPopup(auth, microsoftProvider);
